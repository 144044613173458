import {useAnimation} from '../../../use-animation';
import style from './LoadingDots.module.css';

function Dot({delay}: {delay?: number}) {
	const animation = useAnimation({animation: 'float', delay, infinite: true});

	return <div {...animation}>.</div>;
}

export function LoadingDots() {
	return (
		<div className={style.LoadingDots}>
			<Dot />
			<Dot delay={0.33} />
			<Dot delay={0.66} />
		</div>
	);
}
