import {useRouter} from '@halp/ui/router';
import {constructServerSideProps} from 'utils/ssr';
import {funnelStageToProgressMap} from 'utils/constants';
import {useUser, withUserProtectedRoute} from '@halp/foundation/Users';
import {Paths} from '@halp/foundation/Paths';
import type {FunnelStage} from '@halp/util/constants';
import {Loading} from '@halp/ui/Loading';

export function Dashboard() {
	const {user, loading} = useUser();
	const router = useRouter();

	// TODO @jtsmills redirect for email verification
	// if (user?.onboarding) {
	// 	router.replace(Paths.onboarding);
	// 	return null;
	// }

	if (loading) {
		return <Loading />;
	}

	if (user.admin) {
		router.replace(Paths.admin.index.url());
		return null;
	}

	if (user.coachProfile) {
		router.replace(Paths.coach.index.url());
		return null;
	}

	if (user.partnerProfile) {
		router.replace(Paths.partner.index.url());
		return null;
	}

	if (!user.studentProfile?.qualificationComplete) {
		router.replace(Paths.qualification.url());
		return null;
	}

	if (user.studentProfile) {
		router.replace(
			Paths.student.dashboard.stage.url(
				funnelStageToProgressMap[
					user.studentProfile.funnelStage as FunnelStage
				],
			),
		);
		return null;
	}

	return null;
}

export default withUserProtectedRoute(Dashboard);
export const getServerSideProps = constructServerSideProps({
	dictionaries: [
		'student_dashboard',
		'to_do',
		'program_recommendation',
		'login',
	],
});
