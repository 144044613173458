import {LoadingDots, LoadingSpinner} from './components';
export interface Props {
	type?: 'spinner' | 'mini-spinner' | 'dots';
}

export function Loading({type}: Props) {
	if (type === 'dots') return <LoadingDots />;
	if (type === 'mini-spinner') return <LoadingSpinner mini />;
	return <LoadingSpinner />;
}
